/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import 'variables\.scss';

html, body {
    display: flex;
    flex-direction: column;
    font-family: Roboto, Arial, sans-serif;
    margin: 0;
    height: 100%;
    max-width: 100%;


  }

  app-root {
    width: 100%;
    max-width: 1200px;//TODO
    margin: 0 auto;
    background-color: $background-color;
    position: relative;

    display: flex;
    flex-direction: column;

    height: 100%;

    box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
  }

  .mat-card {
    background-color: $background-color-faded !important;
  }

// TODO apply this styling to drawer content
// html{
//   overflow: -moz-scrollbars-vertical;
//   overflow-y: scroll;
// }
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cdk-overlay-pane.full-screen {
  display: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-width: 100%;
  max-height: none;

  .mat-dialog-container {
    min-height: none;
    max-height: none;
  }
}