$base-color-white: #fff;
$base-color-black: #000;

$admin-color: #D97C54;
$admin-color-highlight: lighten($admin-color, 10%);

.admin-color-filter-black {
    filter: invert(64%) sepia(70%) saturate(892%) hue-rotate(321deg) brightness(90%) contrast(88%);
}

$background-color: #fff;
$background-color-faded: #fafafa;

$user-color: #87BCBF;
$admin-secondary-color: #C4714B;
$valid-green: #191;
$deviation-red: #F90606;
$spacial-blue: #334856;
$add-color: rgb(81, 165, 108);
$add-color-highlight: lighten($add-color, 10%);
$color-faded: #999;
